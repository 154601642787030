/* RESET */
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* End of RESET */

/* Hi DPI Screens */
@media only screen and (min-resolution: 2dppx) {
  body {
    font-size: 16px;
  }
}
body,
html {
  width: 100%;
  overflow-x: hidden;
}

/* End of Hi DPI Screens */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

.btn {
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  outline: none;
  padding: 1rem;
  width: 10rem;
  margin: 0.25rem 0.5rem;
}

.btn-sm {
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.6rem;
  margin: 0 auto;
  outline: none;
  padding: 0.3rem;
  width: 4rem;
  margin: 0.15rem;
}

.btn:disabled {
  background-color: lightgray;
  border-color: #333;
  color: #555;
  cursor: not-allowed;
}
